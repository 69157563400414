<template>
  <div class="register-form-wrap">
    <div class="register-form-content">
      <h2>欢迎注册广联达交易数字综合金融服务平台</h2>
      <el-form
        :model="registerForm"
        :rules="registerFormRules"
        ref="registerForm"
        label-width="150px"
        class="register-form"
      >
        <el-form-item label="手机号" prop="tel">
          <el-input v-model="registerForm.tel" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="图形验证码" prop="verifyCode">
          <div style="display: flex">
            <el-input
              class="login-input gtm-input-none input_42"
              v-model="registerForm.verifyCode"
              placeholder="请输入图形验证码"
            ></el-input>
            <img :src="verifyCodeUrl" class="verifyCodeUrl" alt="验证码" @click="freshKaptchaImage" />
          </div>
        </el-form-item>
        <el-form-item label="手机验证码" prop="captcha" class="gtm-register-verifyCode form-item-left">
          <el-input v-model="registerForm.captcha" class="verifyCode" placeholder="请输入手机验证码"></el-input>
          <el-button v-if="!isHasEffectCode" type="primary" plain class="verify-code-btn" @click="handleGetVerifyCode"
            >获取验证码
          </el-button>
          <el-button v-else type="primary" plain class="verify-code-btn" disabled
            >重新发送({{ effectiveDurationTime }})
          </el-button>
          <p class="verify-code-error-msg" v-if="effectiveDurationTime > 57 && isHasEffectCode">
            <i class="el-icon-success"></i>验证码已发送至您的手机，请注意查收！
          </p>
        </el-form-item>
        <el-form-item label="密码" prop="password" ref="registerPassword">
          <el-input v-model="registerForm.password" type="password" show-password placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            v-model="registerForm.confirmPassword"
            type="password"
            show-password
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
        <el-form-item prop="isChecked" class="isChecked form-item-left">
          <el-checkbox v-model="registerForm.isChecked" class="checkedClass">
            我已经阅读并同意
            <span @click="handleOpenArgument('/platform/agreement')">《广联达用户协议》</span>和<span
              @click="handleOpenArgument('/platform/privacy')"
              >《隐私政策》</span
            >
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('registerForm')" style="width: 100%">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { JSEncrypt } from 'jsencrypt'

export default {
  name: 'RegisterPage',
  components: {},
  data () {
    /**
     * 密码组合规则校验
     */
    let verificatePassword = (rule, value, callback) => {
      if (/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{8,16}$/.test(value)) {
        callback()
      } else {
        // eslint-disable-next-line
        callback('请输入字母、数字、符号至少两种元素组成的密码')
      }
    }

    /**
     * 校验密码和确认密码是否一致
     */
    let verificateConfirmPassword = (rule, value, callback) => {
      if (value === this.registerForm.password) {
        callback()
      } else {
        // eslint-disable-next-line
        callback('请确认密码是否输入正确')
      }
    }

    let agentPhoneReg = new RegExp(/^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/) // 电话号码 （手机和电话号码）
    let agentPhonePass = (rule, value, callback) => {
      if (rule.required && value === '') {
        callback(new Error('请填写手机号码'))
      } else if (value && !agentPhoneReg.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      // 验证码剩余有效时长
      effectiveDurationTime: 59,
      // 是否已经获取验证码
      isHasEffectCode: false,
      // 标志获取验证码按钮是否已经被点击过
      isCheckMobileClick: false,
      validForm: {
        passwordTip1: false,
        passwordTip2: false,
        confirmPasswordTip1: false,
        confirmPasswordTip2: false
      },
      // argumentDialogVisible1: false,
      // argumentDialogVisible2: false,
      // registerDialogVisible: true,
      // 注册表单
      registerForm: {
        // 注册类型
        userType: 'PERSON',
        // 密码
        password: '',
        // 确认密码
        confirmPassword: '',
        // 手机号
        tel: '',
        // 图形验证码
        verifyCode: '',
        // 短信验证码
        captcha: '',
        isChecked: false
      },
      // 注册表单规则校验
      registerFormRules: {
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'blur' },
          { validator: verificatePassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: verificateConfirmPassword, trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' }
          // { validator: verificateCode, trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: agentPhonePass, trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: '请输入手机验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '长度在6个字符', trigger: 'blur' }
        ],
        isChecked: [{ required: true, message: '请阅读并同意该协议', trigger: 'change' }]
      },
      // 验证码 URL
      verifyCodeUrl: ''
    }
  },
  mounted () {
    this.getKaptchaImageFn() // 生成智能验证码
  },
  methods: {
    getKaptchaImageFn () {
      console.log(111)
      this.verifyCodeUrl = this.$guaranteeApi.USER.fetchUserGenVerifyImage()
    },
    // 验证手机号是否合法
    handleValidMobile () {
      let { tel, verifyCode } = this.registerForm
      if (!this.registerForm.verifyCode) {
        this.$message({
          message: '图形验证码不能为空',
          type: 'warning',
          single: true
        })
        return false
      }
      tel = tel.trim()
      verifyCode = verifyCode.trim()
      if (tel) {
        if (/^1\d{10}$/.test(tel)) {
          return true
        } else {
          this.$message({
            message: '请输入合法手机号',
            type: 'warning',
            single: true
          })
          return false
        }
      } else if (!verifyCode) {
        this.$message({
          message: '手机号不能为空',
          type: 'warning',
          single: true
        })
        return false
      } else {
        this.$message({
          message: '手机号不能为空',
          type: 'warning',
          single: true
        })
        return false
      }
    },
    // 获取验证码
    handleGetVerifyCode () {
      if (this.isCheckMobileClick === true) {
        return
      }

      let isEffectTel = this.handleValidMobile()
      if (!isEffectTel) {
        this.getKaptchaImageFn() // 手机号无效的时候 更新生成图形验证码
        return
      }

      this.isCheckMobileClick = true
      let body = {
        mobile: this.registerForm.tel,
        captchaType: 'REGISTER',
        captcha: this.registerForm.verifyCode
      }
      this.$guaranteeApi.USER.fetchUserSendSmsCode({}, body).then(res => {
        if (res && res.code === 0) {
          this.isHasEffectCode = true
          let validInterval = setInterval(() => {
            this.effectiveDurationTime -= 1
            if (this.effectiveDurationTime <= 0) {
              clearInterval(validInterval)
              this.isHasEffectCode = false
              this.effectiveDurationTime = 59
            }
          }, 1000)
        } else {
          // this.$message.error(res.message)
          this.getKaptchaImageFn() // 生成图形验证码
        }
        this.isCheckMobileClick = false
      })
    },
    handleOpenArgument (dialogType) {
      let { href } = this.$router.resolve({
        path: dialogType
      })
      window.open(href, '_blank')
    },
    encryptedData (publicKey, data) {
      // 新建JSEncrypt对象
      let encryptor = new JSEncrypt()
      // 设置公钥
      encryptor.setPublicKey(publicKey)
      // 加密数据
      return encryptor.encrypt(data)
    },
    // 表单提交
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.registerFn()
        } else {
          return false
        }
        // this.freshKaptchaImage();
      })
    },
    async registerFn () {
      if (!this.registerForm.isChecked) {
        this.$message.error('请阅读并同意该协议')
        return
      }
      let getPKey = await this.getPKey()
      if (getPKey && getPKey.code === 0) {
        // eslint-disable-next-line
        let { public_key } = getPKey
        let body = Object.assign(
          {},
          {
            password: this.encryptedData(public_key, this.registerForm.password),
            confirmPassword: this.encryptedData(public_key, this.registerForm.confirmPassword),
            tel: this.registerForm.tel,
            userType: this.registerForm.userType,
            captcha: this.registerForm.captcha,
            verifyCode: this.registerForm.verifyCode,
            loginName: this.registerForm.tel
          }
        )
        this.$guaranteeApi.USER.fetchUserRegister({}, body).then(res => {
          if (res && res.code === 0) {
            this.$confirm('恭喜您，注册成功！', '提示', {
              confirmButtonText: '我知道了',
              showCancelButton: false,
              closeOnClickModal: false,
              type: 'success'
            }).finally(() => {
              this.$router.replace({
                name: 'RegisterSuccess'
              })
            })
          }
        })
      }
    },
    /**
     * 重置表单数据
     */
    resetForm (formName) {
      this.$refs[formName].resetFields()
      this.freshKaptchaImage()
    },
    /**
     * 获取验证码数据信息
     */
    freshKaptchaImage () {
      // 每次刷新
      this.getKaptchaImageFn()
    },
    async getPKey () {
      // eslint-disable-next-line
      return await this.$guaranteeApi.COMMON_LOGIN.fetchGetPublicKey()
    }
  }
}
</script>

<style lang="scss">
.register-form-wrap {
  background: url(../../../assets/images/register/gtm-register-bg@2x.png) center center no-repeat;
  background-size: 100% 100%;
  padding: 4.52rem 0 20px;
  height: 100%;
  box-sizing: border-box;

  .register-form-content {
    width: 1190px;
    margin: auto;
    margin-top: 50px;
    background: #fff;
    padding: 10px 60px 60px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    // text-align: center;
    h2 {
      font-size: 30px;
      font-weight: 600;
      color: #444444;
      margin: 30px 0;
      height: 30px;
      line-height: 30px;
      text-align: center;
    }

    .register-form {
      padding: 40px 310px 40px 230px;
      border: 1px solid #d2d2d2;
    }
  }

  .verifyCode {
    width: calc(100% - 110px);
  }

  .verifyCodeUrl {
    height: 34px;
    float: right;
    width: 95px;
    margin-left: 10px;
  }

  .verify-code-btn {
    height: 34px;
    float: right;
    width: 100px;
    padding: 0px;
  }

  .verify-code-error-msg {
    height: 34px;
    margin-top: 10px;
    margin-bottom: -7px;
    line-height: 34px;
    background: rgba(86, 181, 2, 0.2);
    border-radius: 2px;
    border: 1px solid rgba(86, 181, 2, 0.3);
    font-size: 16px;
    font-weight: 400;
    color: #555555;

    .el-icon-success {
      color: #56b502;
      font-size: 20px;
      margin: 0 10px 0 20px;
    }
  }

  .isChecked {
    .el-form-item__error {
      position: relative;
      display: inline-block;
      top: 0px;
    }

    .checkedClass {
      .el-checkbox__label {
        span {
          color: #0084ee;
        }
      }
    }
  }
}

.gtm-register-verifyCode .el-form-item__error {
  right: 120px;
}

// .gtm-dialog-tip {
//     text-align: center;
//     font-size: 18px;
//     font-weight: bold;
//     color: #555555;
//     margin: 30px 0 20px 0;

//     span::before {
//         content: "";
//         display: inline-block;
//         width: 18px;
//         height: 18px;
//         background: url(../../assets/images/register/gtm-success-tip@2x.png) center center no-repeat;
//         background-size: 100% 100%;
//         margin-right: 10px;
//     }
// }
//提示框样式
.custom-msg-confirm {
  position: relative;
  box-sizing: border-box;
  width: 520px;
  height: 224px;
  border-radius: 2px;

  .el-message-box__content {
    margin: 0px;
    padding: 20px !important;
  }

  .postpaid-confirm-item {
    // display: flex;
    // align-items: center;
    .iconTitle {
      display: flex;
      align-items: center;
    }

    .confirm-title {
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #2e343b;
      line-height: 24px;
      display: inline-block;
      // line-height: 36px;
    }

    .confirm-desc {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #2e343b;
      // width: calc(100% - 34px);
      margin-top: 10px;
      margin-left: 28px;
      line-height: 22px;
    }

    .el-icon-success {
      font-size: 22px;
      margin-right: 6px;
      color: #35b337;
    }
  }

  .el-message-box__btns {
    position: absolute;
    bottom: 20px;
    right: 0px;

    button {
      width: 63.52px;
      height: 34px;

      &:first-child {
        background-color: $--base-color;

        &:hover {
          background-color: $--hover-color;
        }

        &:active {
          background-color: $--click-color;
        }
      }

      &:last-child {
        &:hover {
          background: rgba(0, 130, 230, 0.1);
          border-color: #0082e6;
          color: $--base-color;
        }

        &:active {
          background: $--base-color;
          color: #fff;
        }
      }
    }
  }
}
</style>
